<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <div>
    <app-loading v-show="loading"></app-loading>
    <div v-for="(l, i) in groups" :key="i">
      <h4>{{ l.label }}</h4>
      <div class="btn-group">
        <button
          class="btn btn-outline-primary"
          v-for="(l1, i1) in l.list"
          :key="`${l1.id}_${i1}`"
          :class="{
            active: l1.product_id == productId
          }"
        >
          {{ l1.label }}
        </button>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" @click="clickNewGroupHandler">
        <i class="fas fa-plus"></i>
        Nuevo Grupo
      </button>
    </div>

    <app-modal-basic ref="elModalNew">
      <GroupForm :template_product_id="productId" ref="elForm"></GroupForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { StoreService } from "../StoreService";
import GroupForm from "../product-groups/Form.vue";

export default {
  components: {
    GroupForm
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    groups: [],
    loading: false,
    productId: null
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    clickNewGroupHandler() {
      this.$refs.elModalNew.show();
      this.$refs.elForm.reset();
      this.$refs.elForm.focus();
    },

    reset() {
      this.groups = [];
      this.loading = false;
      this.productId = null;
    },
    async beginFromProductId(productId) {
      this.reset();
      this.productId = productId;
      this.loading = true;
      let paginatedResponse = await StoreService.getProductGroups({
        product_id: productId
      });
      this.groups = paginatedResponse.data;
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
