<template>
  <VueSimpleSuggest
    :filter-by-query="false"
    ref="suggestComponent"
    :list="getList"
    mode="input"
    v-model="valueChild"
    :debounce="300"
    :styles="styles"
  >
    <div slot="suggestion-item" slot-scope="scope">
      <span v-html="boldenSuggestion(scope)"></span>
    </div>
  </VueSimpleSuggest>
</template>

<script>
import { GeneralService } from "../GeneralService";
import simplesuggest from "../../utils/simplesuggest.mixin.js";

export default {
  mixins: [simplesuggest],
  methods: {
    getList(search) {
      return new Promise((rsv) => {
        GeneralService.getMeasurements({
          search
        }).then((res) => {
          rsv(res.data.map((x) => x.name));
        });
      });
    }
  }
};
</script>

<style></style>
