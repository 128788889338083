<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-01-15 22:23 -->
<template>
  <div>
    <div
      class="custom-control custom-radio"
      v-for="(l, i) in PRODUCT_TYPES"
      :key="i"
    >
      <input
        type="radio"
        :id="`${_uid}_${i}`"
        name="customRadio"
        v-model="internalValue"
        class="custom-control-input"
        :value="l.value"
        @input="$emit('input', l.value)"
      />
      <label class="custom-control-label" :for="`${_uid}_${i}`">
        {{ l.type }}
      </label>
    </div>
  </div>
</template>
<script>
const PRODUCT_TYPES = [
  { value: null, type: "TODOS" },
  { value: 1, type: "BIENES" },
  { value: 2, type: "SERVICIOS" }
];

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {
      default: null
    }
  },

  data: () => ({
    PRODUCT_TYPES,
    internalValue: null
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    this.internalValue = this.value;
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
