<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <div>
    <div class="form-group">
      <label for="">Etiqueta <small>(talla, color, modelo)</small></label>
      <input
        ref="elInputLabel"
        class="form-control"
        type="text"
        v-model="label"
        maxlength="50"
      />
      <app-invalid-feedback :errors="errors.label"></app-invalid-feedback>
    </div>
    <!-- <div class="form-group"> -->
    <!--   <label for=""></label> -->
    <!--   <SelectProduct v-model="product_id"></SelectProduct> -->
    <!--   <app-invalid-feedback :errors="errors.product_id"></app-invalid-feedback> -->
    <!-- </div> -->

    <table class="table table-bordered">
      <tr>
        <td class="p-0 px-2 align-middle">
          <input type="text" class="app_input_reset d-inline w-100" />
        </td>
        <td class="p-0">
          <SelectProduct ref="elSelectProduct"></SelectProduct>
        </td>
      </tr>
    </table>

    <app-button-loading :loading="sending" :disabled="errors" @click="save()">
      Guardar
    </app-button-loading>
  </div>
</template>
<script>
import validator from "../../utils/validator.js";
// import SelectProduct from "../products/Select.vue";
import { StoreService } from "../StoreService";
import SelectProduct from "../products/Select.vue";
const formRules = {
  label: { presence: { allowEmpty: false } }
  // product_id: { presence: { allowEmpty: false } }
};

export default {
  components: {
    SelectProduct
  },

  // directives
  // filters

  props: {
    template_product_id: {
      //
    }
  },

  data: () => ({
    id: null,
    label: "",
    product_id: null,
    sending: false,
    list: []
  }),

  computed: {
    errors() {
      return validator(this.$data, formRules);
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    async save() {
      this.sending = true;
      await StoreService.saveProductGroup({
        label: this.label,
        template_product_id: this.template_product_id
      });
      this.sending = false;
    },
    reset() {
      this.label = "";
      this.product_id = null;
    },
    focus() {
      this.$refs.elInputLabel.focus();
    }
  }
};
</script>

<style scoped></style>
